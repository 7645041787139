const states = [
  { state: "Alabama", savings: 0.25, years: 13 },
  { state: "Arizona", savings: 0.2, years: 11 },
  { state: "Arkansas", savings: 0.25, years: 16 },
  { state: "California", savings: 0.5, years: 8 },
  { state: "Colorado", savings: 0.3, years: 13 },
  { state: "Connecticut", savings: 0.3, years: 8 },
  { state: "Delaware", savings: false, years: 12 },
  { state: "Florida", savings: 0.3, years: 13 },
  { state: "Georgia", savings: 0.25, years: 13 },
  { state: "Idaho", savings: 0.25, years: 16 },
  { state: "Illinois", savings: 0.3, years: 13 },
  { state: "Indiana", savings: false, years: 16 },
  { state: "Iowa", savings: false, years: 12 },
  { state: "Kansas", savings: 0.25, years: 12 },
  { state: "Kentucky", savings: 0.25, years: 16 },
  { state: "Louisiana", savings: 0.25, years: 16 },
  { state: "Maine", savings: false, years: 13 },
  { state: "Maryland", savings: false, years: 12 },
  { state: "Massachusetts", savings: 0.3, years: 11 },
  { state: "Michigan", savings: false, years: 13 },
  { state: "Minnesota", savings: 0.25, years: 15 },
  { state: "Mississippi", savings: 0.25, years: 15 },
  { state: "Missouri", savings: 0.25, years: 16 },
  { state: "Montana", savings: false, years: 16 },
  { state: "Nebraska", savings: false, years: 14 },
  { state: "Nevada", savings: 0.3, years: 14 },
  { state: "New Hampshire", savings: false, years: 10 },
  { state: "New Jersey", savings: 0.3, years: 10 },
  { state: "New Mexico", savings: 0.25, years: 11 },
  { state: "New York", savings: 0.3, years: 11 },
  { state: "North Carolina", savings: 0.3, years: 14 },
  { state: "North Dakota", savings: false, years: 16 },
  { state: "Ohio", savings: false, years: 15 },
  { state: "Oklahoma", savings: 0.25, years: 15 },
  { state: "Oregon", savings: false, years: 17 },
  { state: "Pennsylvania", savings: 0.3, years: 14 },
  { state: "Rhode Island", savings: false, years: 10 },
  { state: "South Carolina", savings: 0.3, years: 13 },
  { state: "South Dakota", savings: false, years: 14 },
  { state: "Tennessee", savings: 0.25, years: 19 },
  { state: "Texas", savings: 0.3, years: 13 },
  { state: "Utah", savings: 0.3, years: 13 },
  { state: "Vermont", savings: false, years: 12 },
  { state: "Virginia", savings: 0.3, years: 15 },
  { state: "Washington", savings: false, years: 27 },
  { state: "West Virginia", savings: false, years: 17 },
  { state: "Wisconsin", savings: false, years: 15 },
  { state: "Wyoming", savings: false, years: 14 },
];
export default ({ state }) => {
  const foundState = states.find(
    (s) => s.state.toLowerCase() == state.toLowerCase()
  );
  if (!foundState) return { savings: false, years: false };
  /*
    // Logic based on https://www.vertex42.com/ExcelArticles/amortization-calculation.html
    const periods = 25 * 12
    const interestRate = 0.0299 / 12
    const principal = systemSize * 1000 * foundState.cost

    const numerator = interestRate * Math.pow(1 + interestRate, periods)

    const denominator = Math.pow(1 + interestRate, periods) - 1
    const monthly = principal * (numerator / denominator)
    console.log({ monthly, principal, numerator, denominator })
    */
  const { savings, years } = foundState;
  return { savings, years };
};
