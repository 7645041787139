import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/design/hero";
import Switcher from "../components/design/switcher";
import Footer from "../components/footer";

import calculateMonthly from "../utils/calculateMonthly";
import callShim from "../utils/callShim";
import "aos/dist/aos.css";

//
// Cash is returne on investment, years to pay it off, minus 1
// "Estimated" return on investment.
// Lease is 5% lease
// NIS, it'll just say enquire
//

// We are no longer doing actual savings calculations, we are basing sales off a per state table.
// AOS

// WE removed this briefly <Switcher fluid={smile.childImageSharp.fluid} title='Smile' />
class SavingsCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      bill: "",
      yearlyUsage: "",
      systemSize: "",
      desiredOffset: 1.06,
      stateCode: "",
      systemCost: 0,
      savings: 0,
      cashROI: 0,
    };
  }

  componentDidMount() {
    this.AOS = require("aos");
    this.AOS.init({
      once: true,
    });
  }
  componentWillUnmount() {
    if (this.AOS) {
      this.AOS.refresh();
    }
  }

  billChangeFn = (bill) => {
    this.setState(
      {
        bill,
      },
      () => {
        // If this hasn't changed for half a second fire a calculation
        setTimeout(() => {
          // console.log("timeout called");
          if (this.state.bill == bill) {
            this.calculate();
          }
        }, 500);
      }
    );
  };

  // After address has been chosen from the autocomplete
  addressChosenFn = (address) => {
    this.setState(
      {
        address,
      },
      () => {
        callShim("create", { address }).then(({ genID }) => {
          this.setState(
            {
              genID,
            },
            () => {
              this.calculate();
            }
          );
        });
      }
    );
  };

  // This gets called when there is a address change, or a billing value change
  calculate = () => {
    if (!this.state.genID || !this.state.bill) return;
    const { genID, bill } = this.state;
    const { savings, years } = calculateMonthly({
      state: this.state.stateCode,
    });
    this.setState({ savings, years });
    callShim("usage", {
      genID,
      bill,
    }).then(({ kWh }) => {
      if (kWh) {
        this.setState({ yearlyUsage: kWh }, () => {
          callShim("system", {
            genID,
            usage: kWh,
          }).then(({ systemSize }) => {
            this.setState({ systemSize });
          });
        });
      }
    });
  };

  render() {
    const saveSlides = [
      {
        name: "loan",
        value: this.state.savings
          ? (this.state.savings * 100).toFixed(0) + "%"
          : "Enquire",
        description: this.state.savings ? "Save up to " : "",
      },
      {
        name: "cash",
        value: this.state.years - 1 + " years",
        description: "Return on investment",
      },
      {
        name: "lease",
        value: this.state.savings
          ? (this.state.savings * 90).toFixed(0) + "%"
          : "Enquire", // Deduct 10% off
        description: this.state.savings ? "Save up to " : "",
      },
    ];

    const smileSlides = [
      {
        name: "loan / cash",
        value: "0%",
        description: "Flat rate",
      },
      {
        name: "lease",
        value: "2.9% APR",
        description: "Fixed Low Rate",
      },
    ];
    return (
      <StaticQuery
        query={graphql`
          query {
            save: file(relativePath: { eq: "design/save.jpg" }) {
              childImageSharp {
                fluid(quality: 70, maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            smile: file(relativePath: { eq: "design/smile.jpg" }) {
              childImageSharp {
                fluid(quality: 70, maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            gloat: file(relativePath: { eq: "design/gloat.jpg" }) {
              childImageSharp {
                fluid(quality: 70, maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={({ save, smile, gloat }) => (
          <Layout hideFooter={true}>
            <Hero
              address={this.state.address}
              bill={this.state.bill}
              setAddressFn={(val) => {
                this.addressChosenFn(val);
              }}
              setBillFn={(val) => {
                this.billChangeFn(val);
              }}
              setStateFn={(stateCode) => {
                this.setState({ stateCode });
              }}
              systemSize={this.state.systemSize}
              savings={this.state.savings}
              years={this.state.years}
            />
            {this.state.systemSize && (
              <>
                <Switcher
                  fluid={save.childImageSharp.fluid}
                  title="Save"
                  id="numbers"
                  slides={saveSlides}
                  systemSize={this.state.systemSize}
                />
                <Switcher
                  fluid={smile.childImageSharp.fluid}
                  title="Smile"
                  slides={smileSlides}
                  systemSize={this.state.systemSize}
                />
                <Switcher
                  fluid={gloat.childImageSharp.fluid}
                  title="Gloat"
                  systemSize={this.state.systemSize}
                  yearlyProduction={
                    this.state.yearlyUsage * this.state.desiredOffset
                  }
                />
              </>
            )}
            <Footer
              bill={this.state.bill}
              yearlyUsage={this.state.yearlyUsage}
              systemSize={this.state.systemSize}
              address={this.state.address}
              type={"savings"}
            />
          </Layout>
        )}
      />
    );
  }
}

export default SavingsCalculator;
