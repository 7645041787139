import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import SwitchSlider from './switchSlider'
import GloatSlider from './gloatSlider'
import styled from '@emotion/styled'
import * as Scroll from 'react-scroll';


// switcher-column is styled globally, don't want to put a slider within styled Component and getting lazy
export default ({
    id = '',
    fluid,
    title,
    bill,
    slides = null,
    yearlyProduction = 0,
    systemSize = 0
}) => {

    const aosA = {
        'data-aos': 'fade-in',
        'data-aos-delay': 500,
        'data-aos-duration': 1000
    }
    const aosB = {
        'data-aos': 'fade-in',
        'data-aos-delay': 1000,
        'data-aos-duration': 1000,
    }
    return (

        <BackgroundImage Tag="div" fluid={fluid} className="hero is-fullheight" id={id}>
            <div className="container has-text-centered full-swiper-container">
                <div className="columns switcher-columns is-mobile" style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <StyledButton className="fs-16 m-fs-12 s-fs-16 fw-500 hide-small" to="contact" smooth={true} duration={1000} {...aosB}>
                        Get A Quote
                        </StyledButton>
                    <div className="column is-5 switcher-column" style={{ textAlign: 'center', color: '#fff' }}>

                        <p className="gibson fw-200 fs-20 m-fs-17 s-fs-17" {...aosA}>Switch to Solar and</p>
                        <h2 className="gibson fw-500 fs-110 m-fs-90 s-fs-90 is-uppercase" style={{ marginTop: '-0.2em', marginBottom: '-0.1em' }} {...aosA}>{title}</h2>
                        {title == 'Gloat'
                            ? <GloatSlider bill={bill} yearlyProduction={yearlyProduction} systemSize={systemSize} />
                            : <SwitchSlider title={title} bill={bill} slides={slides} systemSize={systemSize} />
                        }
                    </div>
                    <div className="column is-5 mobile-show">
                        <StyledButton className="fs-16 m-fs-12 s-fs-16 fw-500" to="contact" smooth={true} duration={1000} {...aosB}>
                            Get A Quote
                        </StyledButton>
                    </div>
                </div>

            </div>
        </BackgroundImage >
    )
}



const StyledButton = styled(Scroll.Link)`
    color: #fff;
    background: #D6182B;
    border-radius: 30px;
    padding: 15px 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    &:hover{
        color: #fff;
    }
    &.hide-small{
        @media screen and (max-width: 420px){
            display: none;   
        }
    }
    &.show-small{
        @media screen and (min-width: 421px){
            display: none;   
        }
    }
    width: 172px;
    text-align: center;
    line-height: 1em;
    @media screen and (max-width: 1199px){
        padding: 1.171875rem 0;
        width: 15.234375rem;
    }

    
    @media screen and (max-width: 420px){
        padding: 2.898550724637681rem 0;
        width: 37.198067632850242rem;
        
    }

`