import React from 'react'
import styled from '@emotion/styled'
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import SliderBox from './styledSliderBox'
import image1 from '../../images/design/gloat-slide1.png'
import image2 from '../../images/design/gloat-slide2.png'
import image3 from '../../images/design/gloat-slide3.png'
import image4 from '../../images/design/gloat-slide4.png'


export default ({
    bill,
    yearlyProduction = 0,
    systemSize = 0
}) => {

    const cleanNumber = (theValue) => theValue.toLocaleString(undefined, { maximumFractionDigits: 0 })


    // Confirm this with Tydus
    const pounds = (yearlyProduction / 0.88) * 2.2
    const cars = systemSize / 0.3125
    const tons = systemSize / 0.03
    const trees = yearlyProduction * 0.03

    const slides = [
        {
            png: image1,
            description: `Eliminating over <strong>${cleanNumber(pounds)} lbs</strong> of coal burnt every year`,
            className: 'coal'
        },
        {
            png: image2,
            description: `Not driving ${cleanNumber(cars)} cars <strong>18,000 miles</strong> every year`,
            className: 'car'
        },
        {
            png: image3,
            description: `Recycling <strong>${cleanNumber(tons)} tons</strong> of waste instead of landfill every year`,
            className: 'trash'
        },
        {
            png: image4,
            description: `Planting<br/><strong>${cleanNumber(trees)} trees</strong> every year`,
            className: 'trees'
        }
    ]
    const params = {
        preloadImages: false,

        effect: 'fade',
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        autoplay: {
            delay: 7000
        },
    };

    const aosB = {
        'data-aos': 'fade-in',
        'data-aos-delay': 500,
        'data-aos-duration': 1000
    }
    return (
        <SliderBox className="sliderBox" {...aosB}>
            <Swiper {...params}>
                {slides.map(s => (
                    <div>
                        <StyledGloatBox className={'box card ' + s.className} style={{ backgroundImage: `url(${s.png})` }}>
                            <p className="fs-15 m-fs-11 s-fs-11 roboto">Switching to Solar can massively effect our environment and is equivalent to:</p>
                            <div>
                                <p className="fs-17 s-fs-17 m-fs-15" dangerouslySetInnerHTML={{ __html: s.description }} />
                                <p className="light-grey fw-200 fs-12 s-fs-12 ">Comparisons are based on a <strong>
                                    {parseFloat(systemSize).toFixed(1)} kW</strong> system.</p>
                            </div>
                        </StyledGloatBox>
                    </div>
                ))
                }
            </ Swiper>
        </SliderBox >
    )
}

const StyledGloatBox = styled('div')`
    &.box.card{
        @media screen and (max-width: 420px){
            height: 250px;
        }
        div{
            @media screen and (max-width: 768px){
                width: 100%;
            }
            .fs-17{
                @media screen and (max-width: 1199px){
                    margin-bottom: 0.5em;
                }
            }
        }
    }
    &.coal{
        background-position: 245% -100%;
        @media screen and (max-width: 1199px){
            background-position: 135% -80%;
        }
        @media screen and (max-width: 768px){
            background-position: 100% -310%;
        }
        @media screen and (max-width: 420px){
            background-position: 96% -440%;
        }
        @media screen and (max-width: 375px){
            background-position: 95% -490%;
        }
    }
    &.car{
        background-position: 40% 25%;
        @media screen and (max-width: 1199px){
            background-position: 40% 30%;
        }
        @media screen and (max-width: 768px){
            background-position: 30% 22%;
        }
        @media screen and (max-width: 420px){
            background-position: 26% 22%;
        }
        @media screen and (max-width: 375px){
            background-position: 22% 22%;
        }
    }
    &.trash{
        background-position: 50% 33%;
        @media screen and (max-width: 1199px){
            background-position: 50% 35%;
        }
        @media screen and (max-width: 768px){
            background-position: 50% 33%;
        }
    }
    &.trees{
        background-position: 50% 33%;
        @media screen and (max-width: 1199px){
            background-position: 50% 35%;
        }
        @media screen and (max-width: 768px){
            background-position: 48% 30%;
        }
    }
`