import React, { useEffect } from "react";
import { graphql, StaticQuery } from "gatsby";
import Image from "gatsby-image";
import styled from "@emotion/styled";
import house from "../../assets/house.svg";
import solarPanel1 from "../../assets/solar-panel.svg";

import learnMoreIcon from "../../assets/learn-more-icon.svg";
import learnMoreIconDark from "../../assets/learn-more-icon-dark.svg";

import inputIcon from "../../assets/input-icon.svg";

import AutoComplete from "./autoComplete";
import * as Scroll from "react-scroll";

import blank from "../../assets/design/layers/blank.png";
import small from "../../assets/design/layers/s.png";
import medium from "../../assets/design/layers/m.png";
import large from "../../assets/design/layers/l.png";
import xtraLarge from "../../assets/design/layers/xl.png";

export default ({
  address = "",
  bill = "",
  setAddressFn,
  setStateFn,
  setBillFn,
  systemSize = 0,
  savings,
  years,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          background: file(
            relativePath: { eq: "calculatorNew/final/background.jpg" }
          ) {
            childImageSharp {
              fluid(
                quality: 70
                maxWidth: 2000
                maxHeight: 1269
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }

          houseBlank: file(
            relativePath: { eq: "calculatorNew/houseIcons/blank.png" }
          ) {
            childImageSharp {
              fluid(
                quality: 70
                maxWidth: 273
                maxHeight: 124
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          houseSmall: file(
            relativePath: { eq: "calculatorNew/houseIcons/s.png" }
          ) {
            childImageSharp {
              fluid(
                quality: 70
                maxWidth: 273
                maxHeight: 124
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          houseMedium: file(
            relativePath: { eq: "calculatorNew/houseIcons/m.png" }
          ) {
            childImageSharp {
              fluid(
                quality: 70
                maxWidth: 273
                maxHeight: 124
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          houseLarge: file(
            relativePath: { eq: "calculatorNew/houseIcons/l.png" }
          ) {
            childImageSharp {
              fluid(
                quality: 70
                maxWidth: 273
                maxHeight: 124
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          houseExtraLarge: file(
            relativePath: { eq: "calculatorNew/houseIcons/xl.png" }
          ) {
            childImageSharp {
              fluid(
                quality: 70
                maxWidth: 273
                maxHeight: 124
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={(data) => {
        const aosA = {
          "data-aos": "fade-in",
          "data-aos-delay": 500,
          "data-aos-duration": 1000,
        };

        const aosB = {
          "data-aos": "fade-in",
          "data-aos-delay": 1000,
          "data-aos-duration": 1000,
          "data-aos-anchor": "#home-title",
        };

        const layerStyles = {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center center",
        };

        const layerOutput = (asset) => (
          <div className="hero super-imposed is-fullheight  gatsby-image-wrapper">
            <picture>
              <img src={asset} style={layerStyles} />
            </picture>
          </div>
        );

        const getHouseIconImage = () => {
          if (!systemSize) return data.houseBlank.childImageSharp.fluid;
          if (systemSize <= 4 && systemSize > 0)
            return data.houseSmall.childImageSharp.fluid;

          if (systemSize && systemSize <= 7 && systemSize > 4)
            return data.houseMedium.childImageSharp.fluid;

          if (systemSize && systemSize <= 10 && systemSize > 7)
            return data.houseLarge.childImageSharp.fluid;

          if (systemSize && systemSize > 10)
            return data.houseExtraLarge.childImageSharp.fluid;

          return data.houseBlank.childImageSharp.fluid;
        };
        return (
          <>
            <StyledHero>
              <Image
                className="hero super-imposed is-fullheight"
                fluid={data.background.childImageSharp.fluid}
                {...aosB}
              />

              <div
                className="hero super-imposed is-fullheight  gatsby-image-wrapper"
                {...aosB}
              >
                <picture>
                  <img src={blank} style={layerStyles} />
                </picture>
              </div>
              {systemSize &&
                systemSize <= 4 &&
                systemSize > 0 &&
                layerOutput(small)}

              {systemSize && systemSize <= 7 && systemSize > 4 && (
                <>
                  {layerOutput(small)}
                  {layerOutput(medium)}
                </>
              )}

              {systemSize && systemSize <= 10 && systemSize > 7 && (
                <>
                  {layerOutput(small)}
                  {layerOutput(medium)}
                  {layerOutput(large)}
                </>
              )}

              {systemSize && systemSize > 10 && (
                <>
                  {layerOutput(small)}
                  {layerOutput(medium)}
                  {layerOutput(large)}
                  {layerOutput(xtraLarge)}
                </>
              )}
              <section className="hero is-fullheight">
                <div className="hero-body">
                  <div className="container has-text-centered">
                    <div>
                      <h1
                        className="gibson fw-600 fs-35 m-fs-31 s-fs-37"
                        id="design-title"
                        {...aosA}
                      >
                        Designed for you
                      </h1>
                      <p className="gibson fs-20 m-fs-13 s-fs-18" {...aosB}>
                        Produce and store clean energy
                        <br className="small-only" />
                        with your solar panels and battery
                      </p>
                    </div>
                    <div className="columns hide-small" {...aosB}>
                      <div className="column is-4">
                        <div className="box form-box">
                          <StyledForm className="form" autocomplete="off">
                            <div className="field">
                              <label className="label  fw-100 fs-12 m-fs-10 roboto address">
                                Your home address:
                              </label>
                              <div className="control">
                                <AutoComplete
                                  address={address}
                                  setAddressFn={setAddressFn}
                                  setStateFn={setStateFn}
                                />
                              </div>
                            </div>

                            <div className="field is-horizontal">
                              <label className="label fw-100 fs-12 m-fs-10  roboto bill">
                                Average monthly electrical bill:
                              </label>
                              <div className="control bill-control">
                                <input
                                  className={
                                    "input gibson fs-35 m-fs-28 fw-400  bill " +
                                    (bill ? " populated" : "")
                                  }
                                  name="bill"
                                  type="text"
                                  value={bill ? "$" + bill : ""}
                                  onChange={(e) => {
                                    setBillFn(
                                      e.target.value
                                        ? e.target.value
                                            .replace(/[^0-9.]/g, "")
                                            .split("$")
                                            .pop()
                                        : e.target.value
                                    );
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 9) e.preventDefault();
                                  }}
                                  autocomplete="off"
                                />
                              </div>
                            </div>
                          </StyledForm>
                        </div>
                      </div>
                      {years && (
                        <div className="column is-4">
                          <div className="box is-dark">
                            <div className="columns">
                              <div className="column">
                                <p className="fw-100 fs-12 m-fs-10  roboto">
                                  Recommended system size
                                </p>
                                {systemSize ? (
                                  <p className="fw-100 fs-12 roboto">
                                    <span className="fs-22 m-fs-18 fw-400">
                                      {parseFloat(systemSize).toFixed(1)}
                                    </span>
                                    kW
                                  </p>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div className="column is-narrow">
                                <img className="solarPanel" src={solarPanel1} />
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column flex-bottom">
                                <p className="fw-100 m-fs-10  fs-13 roboto is-uppercase">
                                  Save up to
                                </p>
                                {savings ? (
                                  <p className="fw-100 m-fs-10  fs-12 roboto">
                                    <span className="fs-22 m-fs-18 fw-400">
                                      {(savings * 100).toFixed(0)}%{" "}
                                    </span>{" "}
                                    / month
                                  </p>
                                ) : (
                                  <p className="fw-100 m-fs-10  fs-12 roboto">
                                    <span className="fs-22 m-fs-18 fw-400">
                                      Enquire
                                    </span>
                                  </p>
                                )}
                              </div>
                              <div className="column is-narrow">
                                <Image
                                  className="houseIcon"
                                  fluid={getHouseIconImage()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {systemSize && (
                      <Scroll.Link
                        className="learn-more hide-small"
                        to="numbers"
                        smooth={true}
                        duration={1000}
                      >
                        <p className="fs-14 m-fs-12 fw-500 roboto ls-50">
                          Enquire Now
                        </p>
                        <img src={learnMoreIcon} />
                      </Scroll.Link>
                    )}
                  </div>
                </div>
              </section>
            </StyledHero>
            <StyledMobileForm autocomplete="off" {...aosB}>
              <StyledForm className="form is-small">
                <div className="field">
                  <label className="label  fw-100 fs-12 m-fs-10 roboto address">
                    Your home address:
                  </label>
                  <div className="control">
                    <AutoComplete
                      address={address}
                      setAddressFn={setAddressFn}
                      setStateFn={setStateFn}
                    />
                  </div>
                </div>

                <div className="field is-horizontal">
                  <label className="label fw-100 fs-12 m-fs-10 s-fs-12 roboto bill">
                    Average monthly electrical bill:
                  </label>
                  <div className="control bill-control">
                    <input
                      className={
                        "input gibson fs-35 m-fs-28 s-fs-34 fw-400  bill " +
                        (bill ? " populated" : "")
                      }
                      name="bill"
                      type="text"
                      value={bill ? "$" + bill : ""}
                      onChange={(e) => {
                        setBillFn(
                          e.target.value
                            ? e.target.value
                                .replace(/[^0-9.]/g, "")
                                .split("$")
                                .pop()
                            : e.target.value
                        );
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 9) e.preventDefault();
                      }}
                      autocomplete="off"
                    />
                  </div>
                </div>
              </StyledForm>
              <div className="box is-dark">
                <div className="columns is-mobile">
                  <div className="column">
                    <p className="fw-100 fs-12 m-fs-10  s-fs-13 roboto">
                      Recommended system size
                    </p>
                    {systemSize && (
                      <p className="fw-100 fs-12 s-fs-12 roboto">
                        <span className="fs-22 m-fs-18 s-fs-20 fw-400">
                          {systemSize ? parseFloat(systemSize).toFixed(1) : ""}
                        </span>{" "}
                        kW
                      </p>
                    )}
                  </div>
                  <div className="column is-narrow">
                    <img className="solarPanel" src={solarPanel1} />
                  </div>
                </div>
                <div className="columns is-mobile">
                  <div className="column flex-bottom">
                    <p className="fw-100 m-fs-10 s-fs-13 fs-13 roboto is-uppercase">
                      Save up to
                    </p>
                    {years && (
                      <p className="fw-100 m-fs-10  fs-12 s-fs-12 roboto">
                        {savings ? (
                          <p className="w-100 m-fs-10  fs-12 s-fs-12 roboto">
                            <span className="fs-22 m-fs-18 s-fs-20 fw-400">
                              {(savings * 100).toFixed(0)}%{" "}
                            </span>{" "}
                            / month
                          </p>
                        ) : (
                          <p className="w-100 m-fs-10  fs-12 s-fs-12 roboto">
                            <span className="fs-22 m-fs-18 s-fs-20 fw-400">
                              Enquire
                            </span>
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                  <div className="column is-narrow">
                    <Image className="houseIcon" fluid={getHouseIconImage()} />
                  </div>
                </div>
              </div>
              {systemSize && (
                <Scroll.Link
                  className="learn-more"
                  to="numbers"
                  smooth={true}
                  duration={1000}
                >
                  <p className="fs-14 m-fs-12 s-fs-12 fw-500 roboto ls-50">
                    Enquire Now
                  </p>
                  <img src={learnMoreIconDark} />
                </Scroll.Link>
              )}
            </StyledMobileForm>
          </>
        );
      }}
    />
  );
};

const StyledHero = styled("div")`
  .super-imposed {
    background: none;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    @media screen and (max-width: 420px) {
      height: 45vh;
    }
  }
  .hero.is-fullheight {
    overflow: hidden;
    @media screen and (max-width: 420px) {
      min-height: 45vh;
    }
    &:before {
      @media screen and (max-width: 1199px) {
        height: 120% !important;
      }
    }
  }
  .hero .hero-body {
    z-index: 999;
    align-items: flex-start;
    color: #fff;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 70%
    );
    .container {
      align-items: flex-start;
      justify-content: center;

      padding-top: 12.369791666666667rem;
      .columns {
        width: 100%;

        display: flex;
        justify-content: center;
        margin-bottom: 0;
        &.hide-small {
          @media screen and (max-width: 420px) {
            display: none;
          }
        }
        .column {
          padding-bottom: 0;
          &.is-4 {
            @media screen and (max-width: 960px) {
              width: 45%;
            }
          }
        }
      }
      @media screen and (max-width: 768px) {
        & > div,
        & > .columns {
          width: 80%;
          margin-left: 10%;
        }
      }
    }
  }
  .learn-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 200px;

    p {
      margin-bottom: 8px;
      text-transform: uppercase;
      color: #fff;
    }
    img {
      width: 3.385416666666667rem;
    }
  }
  .button {
    margin-top: 35px;
  }
  .hero .hero-body {
    .box {
      margin: 3.385416666666667rem 0;
      padding: 30px;
      &.form-box {
        padding: 24px 14px 24px 24px;
      }
      @media screen and (max-width: 960px) {
        padding: 24px;
      }
      &.is-dark {
        color: #fff;
        background: #222222;
        text-align: left;
        padding: 24px 14px 24px 24px;
        @media screen and (max-width: 960px) {
          padding: 24px;
        }
        .columns {
          margin-right: 0;
          margin-left: 0;
          &:first-of-type {
            margin-top: 0;
            @media screen and (max-width: 960px) {
              margin-bottom: 15px;
            }
          }
          .column {
            padding: 0;
            &.flex-bottom {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              padding-bottom: 6px;
            }
          }
        }
      }
      height: 160px;
      @media screen and (max-width: 1199px) {
        height: 17rem;
      }
      @media screen and (max-width: 960px) {
        height: 160px;
      }
      .solarPanel {
        width: 80px;
        margin-top: -2.5em;
        @media screen and (max-width: 960px) {
          float: right;
          width: 65px;
          maergin-top: -1em;
        }
      }
      .houseIcon {
        width: 160px;
        margin-top: -0.75em;
        @media screen and (max-width: 960px) {
          margin-top: 0;
          width: 140px;
        }
      }
    }
  }
  .hide-small {
    @media screen and (max-width: 420px) {
      display: none;
    }
  }
  .small-only {
    @media screen and (min-width: 421px) {
      display: none;
    }
  }
  h1 {
    @media screen and (max-width: 420px) {
      margin-top: 15rem;
    }
  }
`;

const StyledForm = styled("form")`
  .label {
    text-align: left;
    color: #707070;
    &.address {
      margin-bottom: 4px;
    }
    &.bill {
      width: 70%;
    }
  }

  .input {
    box-shadow: none;
    border: none;
    background-image: url(${inputIcon}),
      linear-gradient(to right, #fff 0%, #fff 100%),
      linear-gradient(to right, #e8e8e8 33%, rgba(255, 255, 255, 0) 0%);

    background-position: calc(100% + 5px) calc(50% - 2px), 100% bottom, bottom;
    @media screen and (min-width: 769px) {
      background-size: 78px 78px, 4.5rem 3px, 5px 2px;
    }
    @media screen and (max-width: 768px) {
      background-size: 50px 50px, 4.5rem 3px, 5px 2px;
    }
    background-repeat: no-repeat, no-repeat, repeat-x;
    color: #000;
    padding: 10px 2px 10px 2px;
    font-weight: 400;
    height: auto;
    &:hover {
      cursor: pointer;
    }

    &.populated {
      background-image: url(${inputIcon}),
        linear-gradient(to right, #fff 0%, #fff 100%), none;
    }
    &.bill {
      background-position: calc(100% + 5px) calc(50% + 4px), 100% bottom, bottom;

      @media screen and (max-width: 960px) {
        width: 100%;
        margin-left: 0;
      }
    }
  }
  .field.is-horizontal {
    align-items: flex-end;
    .input {
      padding-bottom: 0;
    }
    @media screen and (max-width: 960px) {
      display: flex;
    }
  }

  &.is-small {
    padding: 30px 0px 10px 10px;
    background: #fff;
  }
`;

const StyledMobileForm = styled("div")`
  display: none;
  @media screen and (max-width: 420px) {
    display: block;
    background: #fff;
    padding: 0 18px;
    min-height: 55vh;
    padding-bottom: 20px;
  }
  /* Box styling was copied from the hero */
  .form {
    padding-left: 7.48792270531401rem;
    padding-right: 7.48792270531401rem;
    .input {
      &.bill {
        padding-left: 3rem;
      }
    }
    label {
      &.bill {
        width: 30%;
      }
    }

    .control {
      &.bill-control {
        width: 70%;
      }
    }
  }
  .box {
    margin: 25px 0 20px;
    padding: 24px;
    padding-left: 7.48792270531401rem;
    padding-right: 7.48792270531401rem;
    color: #fff;
    background: #222222;
    text-align: left;
    padding: 24px;

    .columns {
      margin-right: 0;
      margin-left: 0;
      &:first-of-type {
        margin-top: 0;
        margin-bottom: 15px;
      }
      .column {
        padding: 0;
        &.flex-bottom {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding-bottom: 6px;
        }
      }
    }

    .solarPanel {
      float: right;
      width: 65px;
      margin-top: -1em;
    }
    .houseIcon {
      margin-top: 0;
      width: 140px;
    }
  }
  .learn-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #222222;
    text-transform: uppercase;
    margin-bottom: 20px;
    img {
      margin-top: 1.302083333333333rem;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: 420px) {
        width: 5.314009661835749rem;
      }
    }
  }
`;
