import React, { useEffect } from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

class LocationSearchInput extends React.Component {
  /* Figure out the best way of loading in autoComplet here */

  constructor(props) {
    super(props);
    this.state = { address: this.props.address };
  }

  changeInputValue = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      //console.log("address", results[0])

      const formatted_address = results[0].formatted_address;

      const state_component = results[0].address_components.find((a) =>
        a.types.includes("administrative_area_level_1")
      );

      this.setState({ address: formatted_address });
      this.props.setAddressFn(formatted_address);
      this.props.setStateFn(state_component.long_name);

      /*
                if (state_component) {
                    this.props.setStateFn(state_component.long_name)
                }
                */
    });
  };

  render() {
    const searchOptions = {
      componentRestrictions: { country: "US" },
      types: ["address"],
    };

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.changeInputValue}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <input
              {...getInputProps({
                placeholder: "",
                className: "location-search-input input gibson fs-14 fw-400 ",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "gibson fs-14 suggestion-item--active"
                  : "gibson fs-14 suggestion-item";
                // inline style for demonstration purpose
                const style = {
                  backgroundColor: suggestion.active ? "#fafafa" : "#fff",
                };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
