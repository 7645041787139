import styled from '@emotion/styled'

export default styled('div')`
    .card{
        height: 300px;
        padding: 30px 50px 80px;
        
        background-color: #fff;
        background-position: center center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div{
            width: 70%;
            margin: 0 auto;
            .fs-42{
                margin-bottom: 33px;
            }
            .fs-17{
                margin-bottom: 1em;
            }
        }

        @media screen and (max-width:1199px){
            padding: 4.04rem 2.99rem 6rem;

            div{
                width: 100%;
                .fs-42{
                    margin-bottom: 20px;
                }
            }
        }
        @media screen and (max-width:768px){
            height: 250px;
            padding: 4.04rem 2.99rem 8rem;
        }
        @media screen and (max-width:420px){
            height: 220px;
            padding: 5.5rem 7rem 15rem;
        }
        
    }
    .swiper-pagination{
        .swiper-pagination-bullet{
            color: #222;
            opacity: 0.3;
            width: 10px;
            height: 10px;
            margin-right: 20px;
            &:last-of-type{
                margin-right: 0;
            }
            margin-bottom: 10px;
        }
        .swiper-pagination-bullet-active{
            background-color: #222;
            opacity: 1;
        }
    }

    .tabSelectors{
        padding: 0;
        margin: 0 auto;
        li{
            float: left;
            margin-right: 30px;
            &:last-of-type{
                margin-right: 0;
            }
        }
        a{
            color: #ACACAC;
            padding: 2px 5px;
            text-transform: uppercase;
            &.is-active{
                color: #222;
                border-bottom: 2px solid #222;
            }
            
        }
    }
}`
