const urls = {
  system: `/.netlify/functions/calculateSystem`,
  usage: "/.netlify/functions/calculateUsage",
  create: `/.netlify/functions/createAccount`,
};
export default async (route, shimBody) => {
  // console.log(shimBody);
  if (!urls[route]) {
    // console.log("Route missing");
    return;
  }
  const res = await fetch(urls[route], {
    method: "POST",
    body: JSON.stringify(shimBody),
  }).catch((err) => {
    // console.log(`${route} Error`, err);
    return false;
  });
  if (res.status == 200) {
    const json = await res.json();
    return json;
  } else {
    // console.log(`${route} response not 200`, { res });
    return false;
  }
};
