import React, { useRef } from 'react'
import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';
import SliderBox from './styledSliderBox'

export default ({ slides, systemSize = 0 }) => {
    const ref = useRef(null);
    const params = {
        preloadImages: false,
        effect: 'fade',
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    };
    const goTo = (i) => {
        if (ref.current !== null && ref.current.swiper !== null)
            ref.current.swiper.slideTo(i);

    };

    const aosB = {
        'data-aos': 'fade-in',
        'data-aos-delay': 1000,
        'data-aos-duration': 1000,
    }
    return (
        <SliderBox className="sliderBox" {...aosB}>
            <Swiper {...params} ref={ref}>
                {slides.map(s => (
                    <div key={s.name}>
                        <div className="box card">
                            <p className="fs-15 m-fs-11 s-fs-11 roboto">The benefits of Solar just keep on shining!</p>
                            <ul className="tabSelectors">
                                {slides.map((innerS, i) => (
                                    <li key={innerS.name + '-inner'}>
                                        <a className={'fw-500 fs-18 m-fs-13 s-fs-13 gibson ' + (innerS.name == s.name ? 'is-active' : '')} onClick={() => goTo(i)}>
                                            {innerS.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            <div>
                                <p className="fs-24 m-fs-15 s-fs-17 fw-200">
                                    {s.description}
                                </p>
                                <p className="fs-42 m-fs-31 s-fs-31 fw-400" style={{ marginTop: '-0.1em' }}>
                                    {
                                        typeof s.value === 'number'
                                            ? '$' + s.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            : s.value
                                    }
                                </p>
                                <p className="light-grey fs-12 s-fs-12 fw-200">Rates are based on the average amount of electricity a <strong>{parseFloat(systemSize).toFixed(1)} kW</strong> system can produce and the average price of electricity in your state..</p>
                            </div>
                        </div>
                    </div>
                ))
                }
            </ Swiper>
        </SliderBox >
    )
}
